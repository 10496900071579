body {
  padding: 0;
  margin: 0;
  font-family: "Satoshi";
}

@font-face {
  font-family: "Plus-Jakarta-Sans-Bold";
  src: url("./fonts/PlusJakartaSans-Bold.ttf");
  font-display: block;
}
@font-face {
  font-family: "Plus-Jakarta-Sans-BoldItalic";
  src: url("./fonts/PlusJakartaSans-BoldItalic.ttf");
  font-display: block;
}
@font-face {
  font-family: "Plus-Jakarta-Sans-ExtraBold";
  src: url("./fonts/PlusJakartaSans-ExtraBold.ttf");
  font-display: block;
}
@font-face {
  font-family: "Plus-Jakarta-Sans-ExtraBoldItalic";
  src: url("./fonts/PlusJakartaSans-ExtraBoldItalic.ttf");
  font-display: block;
}
@font-face {
  font-family: "Plus-Jakarta-Sans-ExtraLight";
  src: url("./fonts/PlusJakartaSans-ExtraLight.ttf");
  font-display: block;
}
@font-face {
  font-family: "Plus-Jakarta-Sans-ExtraLightItalic";
  src: url("./fonts/PlusJakartaSans-ExtraLightItalic.ttf");
  font-display: block;
}
@font-face {
  font-family: "Plus-Jakarta-Sans-Italic-VariableFont";
  src: url("./fonts/PlusJakartaSans-Italic-VariableFont_wght.ttf");
  font-display: block;
}
@font-face {
  font-family: "Plus-Jakarta-Sans-Italic";
  src: url("./fonts/PlusJakartaSans-Italic.ttf");
  font-display: block;
}
@font-face {
  font-family: "Plus-Jakarta-Sans-Light";
  src: url("./fonts/PlusJakartaSans-Light.ttf");
  font-display: block;
}
@font-face {
  font-family: "Plus-Jakarta-Sans-LightItalic";
  src: url("./fonts/PlusJakartaSans-LightItalic.ttf");
  font-display: block;
}
@font-face {
  font-family: "Plus-Jakarta-Sans-Medium";
  src: url("./fonts/PlusJakartaSans-Medium.ttf");
  font-display: block;
}
@font-face {
  font-family: "Plus-Jakarta-Sans-MediumItalic";
  src: url("./fonts/PlusJakartaSans-MediumItalic.ttf");
  font-display: block;
}
@font-face {
  font-family: "Plus-Jakarta-Sans-Regular";
  src: url("./fonts/PlusJakartaSans-Regular.ttf");
  font-display: block;
}
@font-face {
  font-family: "Plus-Jakarta-Sans-SemiBold";
  src: url("./fonts/PlusJakartaSans-SemiBold.ttf");
  font-display: block;
}
@font-face {
  font-family: "Plus-Jakarta-Sans-SemiBoldItalic";
  src: url("./fonts/PlusJakartaSans-SemiBoldItalic.ttf");
  font-display: block;
}
@font-face {
  font-family: "Plus-Jakarta-Sans";
  src: url("./fonts/PlusJakartaSans-VariableFont_wght.ttf");
  font-display: block;
}
@font-face {
  font-family: "Comic";
  src: url("./fonts/Comic.ttf");
  font-display: block;
}
@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-Variable.ttf");
  font-display: block;
}
