@import "src/assets/scss/breakpoints";

.contactDetails {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr) !important;
  gap: 50px;
  margin-top: 32px;
  .detailsContent {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    .iconWrapper {
      display: flex;
      gap: 10px;
    }
    .contactTitleWrapper {
      display: flex;
      align-items: center;
      .contactTitle {
        font-size: 28px;
        font-weight: 700;
        line-height: 38px;
      }
    }
    .contactDescription {
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
    }
  }
}

@include responsive("mdDown") {
  .contactDetails {
    grid-template-columns: 100% !important;
  }
}

@include responsive("smDown") {
  .contactDetails {
    gap: 16px !important;
    .detailsContent {
      .iconWrapper {
        .contactIcon {
          width: 24px !important;
          height: 24px !important;
        }
      }
      .contactTitleWrapper {
        .contactTitle {
          font-size: 20px !important;
          line-height: 27px !important;
        }
      }
    }
  }
}
