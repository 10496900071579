@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.home {
  .slider {
    box-shadow: 0px 0px 5px $gray-0;
    border-radius: 8px;
    .slides {
      height: 400px;
      @include responsive("mdDown") {
        height: 270px;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    :global {
      .carousel-inner {
        border-radius: 8px;
      }
    }
  }
  .content {
    @include responsive("smDown") {
      margin-top: 25px !important;
    }

    .title {
      font-weight: 700 !important;
      font-size: 34px !important;
      line-height: 40px !important;
      margin: 32px 0px 16px 0px !important;
    }
    .title2 {
      margin: 32px 0px !important;
    }

    .description {
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      .descriptionList {
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
      }
    }
  }
  @include responsive("lgDown") {
    width: 100% !important;
  }
}

@include responsive("smDown") {
  .home {
    .content {
      .title {
        font-size: 28px !important;
      }
    }
  }
}

@include responsive("mdDown") {
  .container {
    max-width: unset !important;
  }
}
