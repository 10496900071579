@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.footer {
  border-top: 1px solid #a3aed0;
  padding: 32px 0;
}

@include responsive("smDown") {
  .footer {
    padding: 6px !important;
  }
}
