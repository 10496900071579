$previous-btn-gray: #8c8c8c;

$header: #222831;
$button-bg: #009b73;
$secondary-color: #707eae;
$danger-btn-color: #f5365c;

// $red: red;

/**
* Color
**/

///Color
$primary: #e8edf1;

$white-0: #fff;
$white-1: #f8f9fe;
$white-2: #f7fafc;
$white-3: #d9d9d9;
$white-4: #dee2e6;
$white-5: #f0f3f6;

//manualUpload color
$manual-spans: #ffffff;
$manual-border: #47548c1f;

// Black
$black-0: #000;
$black-1: #262626;
$black-2: #333333;
$black-3: #222831;

// Gray
$gray-0: #a3aed0;
$gray-1: #8898aa;
$gray-2: #b9bdc0;
$gray-3: #e9ecef;
$gray-4: #47548c;
$gray-5: #d1d5d9;
$gray-6: #707eae;
$gray-7: #808080;

// Blue
$blue-0: #1f76fe;
$blue-1: #32325d;
$blue-2: #525f7f;
$blue-3: #172b4d;

//Yellow
$yellow-0: #f5a524;
$yellow-1: #f4f862;

// Input Colors
$input-placeholder-color: #adb5bd;

// Button Colors
$primary-btn-color: #009b73;

$secondary-btn-color: #ffffff;
$info-btn-color: #0d6efd;
$success-btn-color: #2dce89;
$danger-btn-color: #ff373a;
$warning-btn-color: #fb6340;
$error-color: #c31322;

//Link
$link: #5e72e4;

//Alert color

$alert-default: #3c4d69;
$alert-warning: #fc7c5f;
$alert-danger: #f75676;
$alert-success: #4fd69c;
$alert-info: #37d5f2;
$alert-primary: #7889e8;
$alert-secondary: #f8fbfc;

//Loader color
$loader-color: #009b73;

//Sub- Label color
$sub-label-color: #8c8c8c;
