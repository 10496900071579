@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.sidebar {
  display: none;
  &.isActive {
    display: block;
    width: 100%;
  }

  .closeIcon {
    cursor: pointer;
  }
  .header {
    display: flex;
    padding: 0px 16px;
    height: 50px;
    justify-content: space-between;
    .LogoIcon {
      width: 220px;
      height: 40px;
    }
    @include responsive("mdDown") {
      align-items: center !important;
      width: 100%;
    }
    .closeIcon {
      display: flex;
      align-items: center;
      svg {
        cursor: pointer;
        path {
          stroke: black;
        }
      }
      @include responsive("mdUp") {
        display: none;
      }
    }
    @include responsive("mdDown") {
      margin-top: 16px !important;
    }
  }
  .sidebarContent {
    margin-top: 32px;
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    path {
      fill: #525f7f;
    }

    .active {
      color: #0573b3 !important;
      path {
        fill: $black-3 !important;
      }
    }

    .sidebarItem {
      display: flex;
      cursor: pointer;
      border-radius: 10px;
      align-items: center;
      gap: 24px;
      height: 44px;
      color: black;
      width: 100%;

      .itemNames {
        font-size: 20px;
        font-weight: 700;
      }
      &.active {
        background-color: #f1f1f1;
      }
      &:hover {
        background-color: #ebebeb;
      }
    }
  }
}
