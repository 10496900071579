@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.pickupRequest {
  position: relative;
  .pickupContainer {
    max-width: unset !important;
    padding: 0 !important;
  }
  .pickupWrapper {
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 24px;
    .textColor {
      color: #fff;
    }
    .headingWrapper {
      position: relative;
      justify-content: flex-end;
      padding-bottom: 60px;
    }
  }
  .pickupImg {
    height: 400px;
    @include responsive("mdDown") {
      height: 320px;
    }
    @include responsive("smDown") {
      height: 270px;
    }
    .img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      box-shadow: 1px 1px 10px $gray-0;
      object-fit: cover;
    }
  }
  .content {
    padding: 0px 20px;
    .titleWrapper {
      margin: 32px 0px;
      .title {
        font-weight: 700;
        font-size: 22px;
        line-height: 22px;
        margin: 0 !important;
      }
    }

    .contactWrapper {
      .label {
        font-size: 14px;
        color: $blue-2;
        margin-bottom: 8px;
      }
      .names,
      .btnSubmit {
        .formGroup {
          .label {
            font-size: 16px;
            font-weight: 400;
            color: black;
            margin-bottom: 8px;
          }
          .pickupInput {
            border-radius: 8px !important;
            background: hsla(0, 0%, 94%, 1) !important;
            border: none;
            padding: 10px;
            &::placeholder {
              color: black;
            }
          }
        }
        .pickupBtnSubmit {
          pointer-events: initial !important;
          font-size: 16px;
          font-weight: 700;
          margin-top: 20px;
          padding: 10px;
          color: white;
          background: black;
          opacity: unset;
          &:active {
            background-color: hsla(0, 0%, 94%, 1) !important;
            color: black !important;
          }
        }
        display: grid;
        grid-template-columns: 100%;
        margin-bottom: 0 !important;
        @include responsive("mdDown") {
          grid-template-columns: 1fr;
          gap: 0;
        }
      }
      .formGroup {
        .inputGroupStyle {
          border: none !important;
        }
      }
      .submitButton {
        border: none;
        @include responsive("mdDown") {
          width: 100%;
        }
      }
    }
  }
  @include responsive("mdDown") {
    width: 100% !important;
    .pickupWrapper {
      gap: 0px !important;
      grid-template-columns: 100% !important;
    }
  }
}
