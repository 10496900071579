@import "src/assets/scss/colors.scss";
@import "src/assets/scss/breakpoints.scss";

.headerWrapper {
  box-shadow: 0px 5px 10px 0px hsla(0, 0%, 0%, 0.25) !important;
  background-color: white;
  top: 0 !important;
  position: sticky !important;
  z-index: 999 !important;

  .container {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @include responsive("sm") {
      height: 70px;
    }
    .Logo {
      cursor: pointer;
      height: fit-content;
    }
    .burger {
      visibility: hidden;
      @include responsive("mdDown") {
        visibility: visible;
      }
      svg {
        cursor: pointer;
      }
    }
    .tabsWrapper {
      .nav {
        gap: 32px;
        align-items: center;
        .btnContactUs {
          background: hsla(0, 0%, 0%, 1);
          border-radius: 4px;
          padding: 8px 20px;
          font-weight: 700;
          font-size: 20px;
          &:active {
            background-color: hsla(0, 0%, 94%, 1) !important;
            color: black !important;
          }
        }

        .navItem {
          .navLink {
            text-decoration: none;
            color: hsla(0, 0%, 0%, 1);
            font-size: 20px;
            font-weight: 700;
          }
          .isActive {
            color: hsla(202, 95%, 36%, 1);
          }
        }
      }
    }
    @include responsive("smDown") {
      height: 80px;
    }

    @include responsive("xlDown") {
      .Logo {
        .LogoIcon {
          width: 300px !important;
        }
      }

      .tabsWrapper {
        .nav {
          .btnContactUs {
            font-size: 16px !important;
          }
          .navItem {
            .navLink {
              font-size: 16px !important;
            }
          }
        }
      }
    }

    @include responsive("lgDown") {
      .Logo {
        .LogoIcon {
          width: 220px !important;
        }
      }
      .tabsWrapper {
        .nav {
          .btnContactUs {
            padding: 8px 10px !important;
          }
          .navItem {
            .navLink {
              font-size: 16px !important;
            }
          }
        }
      }
    }
  }
}

@include responsive("mdDown") {
  .headerWrapper {
    .container {
      justify-content: center !important;
      .Logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 48px) !important;
        order: 1 !important;
        .LogoIcon {
          margin-left: -24px !important;
        }
      }
    }
  }
}

@include responsive("xxlDown") {
  .headerWrapper {
    .container {
      .tabsWrapper {
        .nav {
          gap: 18px !important;
        }
      }
    }
  }
}
