@import "src/assets/scss/breakpoints";

.trackerWrapper {
  .shipmentTitle {
    font-size: 28px;
    font-weight: 700;
  }

  .shipmentNumber {
    font-size: 20px;
    font-weight: 700;
  }
  .trackingInput {
    border: none !important;
    height: 40px !important;
  }

  .searchIcon {
    cursor: pointer !important;
    background: transparent !important;
    border: none;
    padding: 0;
    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
    }
  }
}

@include responsive("smDown") {
  .trackerWrapper {
    .shipmentTitle {
      font-size: 20px !important;
    }
    .shipmentNumber {
      font-size: 16px !important;
      font-weight: 400 !important;
    }
  }
}
