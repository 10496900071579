@import "src/assets/scss/breakpoints";

.heroWrapper {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  height: 100%;

  .gridImage {
    position: absolute !important;
    width: 60% !important;
    left: 0 !important;
    z-index: 0 !important;
    height: 100% !important;
    @include responsive("xxlUp") {
      width: 55% !important;
    }
  }

  .titleWrapper {
    max-width: 600px;
    min-height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      font-weight: 900;
      font-size: 55px;
      line-height: 70px;
      margin: 0 !important;
    }

    .description {
      margin: 32px 0px;
      font-weight: 500;
      font-size: 20px;
      line-height: 27px;
    }
  }
}

@include responsive("smDown") {
  .heroWrapper {
    background-position: 30% !important;
    .titleWrapper {
      max-width: 250px !important;
      min-height: 360px !important;
      .title {
        font-weight: 700 !important;
        font-size: 20px !important;
        line-height: 27px !important;
      }
      .description {
        margin: 16px 0px !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 20px !important;
      }
    }
  }
}

@include responsive("mdDown") {
  .heroWrapper {
    .titleWrapper {
      justify-content: center !important;
      padding: 0 !important;
    }
  }
  .trackerWrapper {
    margin-top: 16px !important;
    .trackerContainer {
      max-width: unset !important;
    }
  }
}
