@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  background: $white-0;
  box-shadow: 0 0 32px rgba(136, 152, 170, 0.15);
  border-radius: 6px;
  border: none;
  overflow: auto;

  .tableWrapper {
    .loader {
      position: relative;
      display: flex;
      justify-content: center;
      padding: 30px;
    }

    .paginationButtons {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .onPage {
        color: #3ba5c0;
        border-bottom: 1px solid #3ba5c0;
      }

      button {
        background-color: #ffff;
        border: none;
        cursor: pointer;
        img {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
      .hide {
        visibility: hidden;
      }
    }

    .pageIndex {
      button {
        margin-right: 5px;
        margin-left: 5px;
        background-color: #ffff;
        border: none;
        cursor: pointer;
      }
    }
  }
  // .tableHeader {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   min-height: 68px;
  //   padding: 20px 24px;
  //   gap: 20px;
  //   @include responsive("xsDown") {
  //     flex-direction: column;
  //     gap: 20px;
  //     align-items: baseline;
  //   }
  //   .tableHeading {
  //     display: flex;
  //     align-items: center;
  //     gap: 32px;
  //     .seeAll {
  //       button {
  //         background-color: $blue-0 !important;
  //       }
  //     }
  //   }
  //   .search {
  //     max-width: 313px;
  //     width: 100%;
  //     border: 1px solid #dee2e6;
  //     box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
  //     border-radius: 4px;
  //     .searchInput {
  //       &:focus,
  //       &:focus-within,
  //       &:active,
  //       &:focus-visible {
  //         outline: 1px solid $primary-btn-color;
  //         box-shadow: none;
  //         border: none;
  //       }
  //     }
  //   }
  // }

  table {
    width: 100%;
    text-align: left;
    border-spacing: 0px;
    // @include responsive("mdDown") {
    //   min-width: 1200px;
    // }

    thead {
      background: #fafafa;
      tr {
        // @include responsive("mdDown") {
        //   min-width: 1300px !important;
        // }
        th {
          font-size: 11px;
          padding: 14px 24px;
          align-items: center;
          font-size: 13px;
        }
      }
    }

    tbody {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      tr {
        border-bottom: 2px solid #efefef;

        td {
          white-space: nowrap;
        }
      }
    }

    th {
      align-items: center;
      padding: 20px 20px 23px 20px;
      text-transform: uppercase;
    }

    td {
      padding: 18px 24px;
    }
  }
}
