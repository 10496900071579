@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.Service {
  .serviceImg {
    height: 400px;
    @include responsive("mdDown") {
      height: 320px;
    }
    @include responsive("smDown") {
      height: 270px;
    }
    .Img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      box-shadow: 1px 1px 10px $gray-0;
      object-fit: cover;
    }
  }
  .content {
    .title {
      font-size: 34px;
      margin: 32px 0px !important;
      font-weight: 700;
      line-height: 40px;
      text-align: center;
    }
    .description {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
      .serviceContent {
        border-radius: 8px;
        .serviceTitleContainer {
          margin-bottom: 16px;
          display: flex;
          gap: 16px;

          .serviceTitle {
            font-size: 28px;
            font-weight: 700;
            line-height: 37.8px;
            margin-bottom: 0 !important;
          }
        }

        .serviceDescription {
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          margin: 0 !important;
        }
      }
      @include responsive("lgDown") {
        grid-template-columns: 1fr 1fr;
      }
      @include responsive("mdDown") {
        grid-template-columns: 1fr;
      }
    }
  }
  @include responsive("lgDown") {
    width: 100% !important;
  }
}

@include responsive("smDown") {
  .Service {
    .content {
      margin-top: 25px !important;
      .title {
        font-size: 28px !important;
      }
      .description {
        gap: 16px !important;
        .serviceContent {
          padding: 0 !important;
          .serviceTitleContainer {
            justify-content: center !important;
            .servicesIcon {
              width: 24px !important;
              height: 24px !important;
            }
            .serviceTitle {
              font-size: 20px !important;
              line-height: 28px !important;
            }
          }
          .serviceDescription {
            text-align: center !important;
          }
        }
      }
    }
  }
}

@include responsive("mdDown") {
  .container {
    max-width: unset !important;
  }
}
