@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.titleColor {
  color: #fff;
}

.AboutUs {
  // height: calc(100vh - 148px);
  .shipment {
    height: 400px;
    @include responsive("mdDown") {
      height: 320px;
    }
    @include responsive("smDown") {
      height: 270px;
    }
    .shipmentImg {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      box-shadow: 1px 1px 10px $gray-0;
      object-fit: cover;
    }
  }
  .content {
    @include responsive("smDown") {
      margin-top: 25px !important;
    }

    .title {
      margin: 32px 0px !important;
      font-weight: 700 !important;
      font-size: 34px !important;
      line-height: 40px !important;
      text-align: center;
    }
    .description {
      margin-top: 20px;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
    }
  }
  @include responsive("lgDown") {
    width: 100% !important;
  }
}

@include responsive("smDown") {
  .AboutUs {
    .content {
      .title {
        font-size: 28px !important;
      }
      .description {
        text-align: center !important;
      }
    }
  }
}

@include responsive("mdDown") {
  .container {
    max-width: unset !important;
  }
}
