@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.ContactUs {
  position: relative;
  .contactUsContainer {
    max-width: unset !important;
    padding: 0 !important;
  }
  .contactInfo {
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 24px;
    .headingWrapper {
      position: relative;
      justify-content: flex-end;
      padding-bottom: 60px;
    }

    @include responsive("mdDown") {
      flex-direction: column;

      .contactDetails {
        width: 100%;
        margin-top: 30px;
        padding: 0;
        gap: 16px;
      }
    }
  }

  .content {
    padding: 0px 20px;
    .titleWrapper {
      margin: 32px 0px;
      .title {
        font-weight: 700;
        font-size: 22px;
        line-height: 22px;
        margin: 0 !important;
      }
    }
    .contactWrapper {
      .names,
      .btnSubmit {
        .formGroup {
          .label {
            font-size: 16px;
            font-weight: 400;
            color: black;
            margin-bottom: 8px;
          }
          .contactInput {
            background-color: hsla(0, 0%, 94%, 1) !important;
            border-radius: 8px !important;
            padding: 10px;
            border: none !important;
            &::placeholder {
              color: black;
            }
          }
        }
        .contactBtnSubmit {
          font-size: 16px;
          margin-top: 20px;
          font-weight: 700;
          padding: 10px;
          color: white;
          background: black;
          pointer-events: initial !important;
          opacity: unset;
          &:active {
            background-color: hsla(0, 0%, 94%, 1) !important;
            color: black !important;
          }
        }
        display: grid;
        grid-template-columns: 100%;
        margin-bottom: 0 !important;
        @include responsive("mdDown") {
          grid-template-columns: 1fr;
          gap: 0;
        }
      }
      .formGroup {
        .inputGroupStyle {
          border: none !important;
        }
        .label {
          font-family: "Plus-Jakarta-Sans-Medium";
          font-size: 14px;
          color: $blue-2;
          margin-bottom: 8px;
        }
      }
      .submitButton {
        border: none;
        @include responsive("mdDown") {
          width: 100%;
        }
      }
    }
  }
  @include responsive("lgDown") {
    width: 100% !important;
  }
}

@include responsive("mdDown") {
  .ContactUs {
    .contactInfo {
      gap: 0px !important;
      grid-template-columns: 100% !important;
    }
  }
}
