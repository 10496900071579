@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.required {
  color: $danger-btn-color;
  margin-left: 3px;
}
.label {
  font-family: "Plus-Jakarta-Sans-Medium";
  color: $black-1;
  text-align: left;
  width: 100%;
  font-size: 14px;
}

.inputStyling {
  font-family: "Plus-Jakarta-Sans-Medium";
  background: $white-0;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  font-size: 14px;
  height: 48px;
  width: 100%;
  color: $black-1;
  text-align: left;
  width: 100%;

  &:focus,
  &:active,
  &:focus-visible {
    border: none;
    box-shadow: none;
    color: $black-2;
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s;
  }

  &::placeholder {
    color: $input-placeholder-color;
    font-size: 14px;
  }
}

:global {
  .pac-container {
    border: 1px solid $white-4;
    box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
  }
}

.requiredLabel {
  margin-left: 3px;
  color: $danger-btn-color;
}

.isInvalid {
  border: 1px solid $error-color !important;
  outline: none !important;
}

.invalidFeedback {
  text-align: left;
  display: flex;
  color: $error-color;
  font-size: 12px;
}

.formGroupStyling {
  border: 1px solid $gray-0;
  border-radius: 10px;
  overflow: hidden;

  &:focus,
  &:focus-within,
  &:active,
  &:focus-visible {
    box-shadow: none;
    border: 1px solid $gray-0;
  }
}

.iconStyling {
  background: $white-0 !important;
  border: none !important;
  color: $input-placeholder-color !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
