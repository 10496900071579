@import "src/assets/scss/colors";

.typography {
  font-size: var(--size);
  margin: 0;
}

.jakarta {
  font-family: "Plus-Jakarta-Sans";
}
// .comic {
//   font-family: "Comic";
// }

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}
