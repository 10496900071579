@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
  .mainContainer {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    @include responsive("mdDown") {
      width: 100%;
    }
    &.hideContainer {
      display: none;
    }
    .content {
      min-height: calc(100vh - 100px);
      background-color: #fff;
      .contentWrapper {
        .trackShipment {
          box-shadow: 1px 1px 10px $gray-1;
          background-color: #dee2e5;
          border-radius: 8px;
          padding: 15px;
          height: fit-content;
          position: sticky;
          top: 125px;
          .trackLabel {
            padding-bottom: 10px;
            border-bottom: 1px solid $gray-0;
          }
          .trackRadio {
            padding: 10px 0;
          }
          .searchIcon {
            cursor: pointer;
            background: transparent !important;
            border: none;
            padding: 0;
            &:hover,
            &:active,
            &:focus {
              box-shadow: none;
            }
          }
          .trackingField {
            height: 40px;
            border: none;
          }
        }
        @include responsive("lgDown") {
          grid-template-columns: 1fr;
        }
      }
      .footer {
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        line-height: 27px;
        color: hsla(0, 0%, 50%, 1);
        margin-top: 32px !important;
      }
    }
  }
}
