@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 85vh;
}
.infoNotFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 224px) !important;

  @include responsive("mdDown") {
    height: calc(100vh - 251px) !important;
  }
  @include responsive("smDown") {
    height: calc(100vh - 180px) !important;
  }
}

.titleWrapper {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}

.textColor {
  color: hsla(0, 0%, 50%, 1);
}

.trackShipment {
  .tracking {
    .content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin: 32px 0px;
      .trackingContent {
        color: hsla(0, 0%, 50%, 1);
        line-height: 27px;
        font-weight: 400;
        font-size: 20px;
        .trackingDetails {
          color: black;
        }
        .status {
          background-color: #5cb85c;
          color: white;
          padding: 3px 7px;
          border-radius: 5px;
        }
      }
      .trackingNo {
        margin-bottom: 32px !important;
      }
      .trackingContent2 {
        margin-top: 32px !important;
      }
    }
  }
  .shipmentHistory {
    .historyTable {
      width: 100%;
      overflow-x: auto;
      table {
        thead {
          th {
            font-size: 20px !important;
            font-weight: 400 !important;
            color: hsla(0, 0%, 50%, 1) !important;
            text-transform: capitalize !important;
          }
        }
        tbody {
          background: hsla(0, 0%, 94%, 1) !important;
          tr {
            border-bottom: 6px solid #fff !important;
          }
        }
      }
      @include responsive("smDown") {
        margin: 0 auto;
        height: auto;
      }
    }
  }
  @include responsive("lgDown") {
    width: 100% !important;
  }
}
@include responsive("smDown") {
  .trackShipment {
    .tracking {
      .content {
        grid-template-columns: 100% !important;
        .trackingContent {
          line-height: 23px !important;
          font-size: 16px !important;
        }
      }
    }
  }
}

@include responsive("mdDown") {
  .titleWrapper {
    flex-direction: column;
  }
  .trackShipment {
    .shipmentHistory {
      .shipmentDetails {
        .shipmentContent {
          margin-bottom: 8px !important;
          padding: 8px !important;
          background: hsla(0, 0%, 94%, 1) !important;
          border-radius: 4px !important;
          display: grid !important;
          grid-template-columns: repeat(2, 1fr) !important;
          gap: 50px !important;
          .descriptionWrapper {
            display: flex !important;
            gap: 4px !important;
            flex-direction: column !important;
            .description {
              font-size: 16px !important;
              font-weight: 400 !important;
              line-height: 23px !important;
              .title {
                color: hsla(0, 0%, 50%, 1) !important;
              }
            }
          }
        }
      }
    }
  }
}
